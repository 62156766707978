import React from "react";
import "../blog-entry-preview.scss"
import "./../blog-entry-style.scss"
import ImageContainer from "../../image-container/image-container";
import CodeContainer from "../../code-container/code-container";

export default function CollectionDownloadUrl() {
    return (
        <div className="blog-entry">
            <div className="content">
                <h1>Collection download via url</h1>
                <ul>
                    <li>
                        <a className={"jump-link"} href="#download-link"> Generate a download link for your
                            collections </a>
                    </li>
                </ul>
                <hr/>
                <h3>
                    <section id="download-link">Generate a download link for your collections</section>
                </h3>
                <p>Postmans pricing is really weird. The first paid tier only gives you more team members, which
                    allows you to share your Postman collections more easily in a team, but the possiblity to prevent
                    others from directly changing the main collection is only possible in the next tier.
                </p>
                <p>
                    But what not everyone knows, is that you dont even need the first paid tier to share your
                    collections within your team (and no iam not talking about sending the collection as json to
                    team members).
                </p>
                <p>
                    Postman has an extensive API, completely for free, which lets you get a json representation of any
                    collection:
                </p>
                <CodeContainer text={"get a specific collection via rest"}>
                    GET https://api.getpostman.com/collections/COLLECTION_UID?apikey=:API_KEY
                </CodeContainer>
                <p>
                    Its also possible to directly insert an url in the "Import Collection" dialog:
                </p>
                <ImageContainer path={require("./share-postman-collection.png")}
                                text={"Share Postman Collection"}/>
                <p>
                    If you combine both steps, you can basically create a download link for your collection and share
                    them (as long as you are willing to provide the api key to others).
                </p>
                <ol type="1">
                    <li>Create an Postman account (a team with only 3 members is for free).</li>
                    <li>Create an apikey as described <a target="_blank" rel="noreferrer"
                                                         href={"https://learning.postman.com/docs/developer/intro-api/#generating-a-postman-api-key"}>here</a>.
                    </li>
                    <li>Get the <b>uid</b> of your collection via this restcall:<br/>
                        <CodeContainer text={"get collection uid"}>
                            GET https://api.getpostman.com/collections?apikey=API_KEY
                        </CodeContainer>
                    </li>
                    <li>This is now your download link:<br/>
                        <CodeContainer text={"download url for your collection"}>
                            GET https://api.getpostman.com/collections/COLLECTION_UID?apikey=API_KEY
                        </CodeContainer>
                    </li>
                    <li>Share it <b>within</b> your team</li>
                </ol>
                <p>
                    PS: since you expose the apikey, its important that you only share this url
                    with <b>trusted</b> members from your team.
                </p>
                <hr/>
                <p>
                    I hope you liked this small trick. More will come.
                </p>
            </div>
        </div>
    );
}