import React, {useEffect, useState} from "react";
import PostmanFeatureFlags from "../blog-entries/postman-feature-flags/PostmanFeatureFlags";
import "./preview.scss"
import {Link, useLocation} from "react-router-dom";

export default function Preview() {

    let [arr, setArr] = useState([
        {enabled: true, url: "/collection-download-url", text: "Postman collection download"},
        {enabled: true, url: "/feature-flags-in-postman", text: "Feature Flags in Postman"},
        {enabled: true, url: "/fzf-with-docker", text: "Fzf with docker"},
        {enabled: true, url: "/postfixes-in-intellij", text: "Postfixes in Intellij"},
        //{enabled: true, url: "/devbox", text: "Provisioning of a dev environment"}
    ]);

    let [fakes, setFakes] = useState([
        {enabled: true, url: "", text: ""},
        {enabled: true, url: "", text: ""},
        {enabled: true, url: "", text: ""},
        {enabled: true, url: "", text: ""}
    ]);

    const location = useLocation();

    useEffect(() => {
        for (let i = 0; i < fakes.length; i++) {
            fakes[i].enabled = true;
        }
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].url === location.pathname) {
                arr[i].enabled = false;
            }
            if (location.pathname === "/") {
                arr[i].enabled = true;
            }
        }

        setArr([...arr]);
        setFakes([...fakes]);
    }, [location]);

    return (
        <div className="preview" style={{backgroundColor: '#06407f'}}>
            <div className="preview-inner">
                <div className={"fill"}>
                    <div className="bubble">
                        {
                            arr.map((item, i) => {
                                return (
                                    <div key={i} className={"bubble-inner"}>
                                        <Link className={item.enabled ? "" : "clicked"}
                                              to={item.url}
                                              onClick={() => {
                                                  window.scrollTo({top: 0, behavior: 'smooth'});
                                                  arr[i].enabled = false;
                                                  setArr([...arr]);
                                              }}>
                                            <svg viewBox='0 0 1 1'></svg>
                                        </Link>
                                        <p className={item.enabled ? "" : "clicked"}>{item.text}</p>
                                    </div>
                                );
                            })
                        }
                        {
                            fakes.map((item, i) => {
                                return (
                                    <div key={i} className={"bubble-inner"}>
                                        <a className={item.enabled ? "" : "clicked"}
                                           onClick={() => {
                                               fakes[i].enabled = false;
                                               setFakes([...fakes]);
                                           }}>
                                            <svg viewBox='0 0 1 1'></svg>
                                            <p>{item.text}</p>
                                        </a>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}