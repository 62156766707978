import React from "react";
import "./contact.scss";

export default function Contact() {
    return (
        <div className="blog-entry">
            <div className="content">
                <h1>Contact</h1>
                <img className={"contact"} alt="contact" src={require("./contact.png")}></img>
            </div>
        </div>
    );
}