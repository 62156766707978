import React from "react";
import ImageContainer from "../../image-container/image-container";
import CodeContainer from "../../code-container/code-container";

export default function FzfWithDocker() {
    return (
        <div className="blog-entry">
            <div className="content">
                <h1>Fzf with docker</h1>
                <p>
                    <a href={"https://github.com/junegunn/fzf"}>Fzf</a> is a fuzzy finder for the cli. You can pipe in
                    anything, as long as its a list, and it allows you to have a fuzzy search over the result. You can
                    also add a preview window for each selection and also add an command to execute when you select
                    something.
                </p>
                <p>
                    When you combine the paragraph above with the title, its obvious where i want to go:
                    Fzf and docker are a super nice match.
                    Docker cli gives you often a result of multiple containers/images, you can pipe in the selected
                    container id to another docker command and the previews are the docker logs itself.
                </p>
                <p>
                    Here is an example for docker exec, jumping directly into a container with fuzzy search and
                    log preview:
                </p>
                <ImageContainer path={require("./fzf-docker-exec.gif")}
                                text={"docker logs with fuzzy selection"}></ImageContainer>
                <p>This is how fzf with docker logs looks like:</p>
                <ImageContainer path={require("./fzf-docker-logs.gif")}
                                text={"docker logs with fuzzy selection"}></ImageContainer>
                <p>The general structure of each call is as follows:</p>
                <ol>
                    <li>We first try to get the container id via fuzzy search.
                        <CodeContainer text={"gives you a fuzzy search over all (running) containers"}>
                            {
                                "fzf_docker(){\n" +
                                "\tall_flag=$1\n" +
                                "\tshift\n" +
                                "\tcid=$(docker ps \"$all_flag\" --format \"table {{.ID}}\\t{{.Image}}\\t{{.Status}}\\t{{.Ports}}\" | sed 1d \\\n" +
                                "\t  | fzf --preview 'docker logs $(echo {} | cut -d \" \" -f1) -n $FZF_PREVIEW_LINES' --height='70%' \\\n" +
                                "\t  --header=\"Select Container\" --preview-window follow:50%:down:rounded:wrap --multi -1 -q \"$1\" | awk '{print $1}')\n" +
                                "\n" +
                                "\t[ -n \"$cid\" ] && echo \"$cid\"\n" +
                                "}"
                            }
                        </CodeContainer>
                    </li>
                    <li> We then pipe the result into a docker command via xargs:
                        <CodeContainer text={"piping step 1 into docker"}>
                            {"dl(){\n" +
                                "\tfzf_docker \"-a\" \"$@\" | xargs -I{} docker logs {} -f\n" +
                                "}"
                            }
                        </CodeContainer></li>
                </ol>
                <p>
                    Download the script and source it into your shell via .bashrc or .zshrc etc, then run one of the
                    commands to see the true power
                </p>
                <CodeContainer text={"download link"}>
                    {"#!/bin/sh\n" +
                        "\n" +
                        "fzf_docker(){\n" +
                        "\tall_flag=$1\n" +
                        "\tshift\n" +
                        "\tcid=$(docker ps \"$all_flag\" --format \"table {{.ID}}\\t{{.Image}}\\t{{.Status}}\\t{{.Ports}}\" | sed 1d \\\n" +
                        "\t  | fzf --preview 'docker logs $(echo {} | cut -d \" \" -f1) -n $FZF_PREVIEW_LINES' --height='70%' \\\n" +
                        "\t  --header=\"Select Container to go into\" --preview-window follow:50%:down:rounded:wrap --multi -1 -q \"$1\" | awk '{print $1}')\n" +
                        "\n" +
                        "\t[ -n \"$cid\" ] && echo \"$cid\"\n" +
                        "}\n" +
                        "\n" +
                        "dex(){\n" +
                        "\tcid=$(fzf_docker \"-s\" \"$@\") #needs to be different since we open a tty\n" +
                        "\tdocker exec -it \"$cid\" sh\n" +
                        "}\n" +
                        "\n" +
                        "dl(){\n" +
                        "\tfzf_docker \"-a\" \"$@\" | xargs -I{} docker logs {} -f\n" +
                        "}\n" +
                        "\n" +
                        "dsta(){\n" +
                        "\tfzf_docker \"-a\" \"$@\" | xargs -I{} docker start {}\n" +
                        "}\n" +
                        "\n" +
                        "dsto(){\n" +
                        "\tfzf_docker \"-s\" \"$@\" | xargs -I{} docker stop {}\n" +
                        "}\n" +
                        "\n" +
                        "di(){\n" +
                        "\tfzf_docker \"-a\" \"$@\" | xargs -I{} docker inspect {}\n" +
                        "}\n" +
                        "\n" +
                        "dre(){\n" +
                        "\tfzf_docker \"-s\" \"$@\" | xargs -I{} docker restart {}\n" +
                        "}\n" +
                        "\n" +
                        "drm(){\n" +
                        "\tfzf_docker \"-a\" \"$@\" | xargs -I{} docker rm -f {}\n" +
                        "}\n"}
                </CodeContainer>
                <p>
                    I use this script every day and it saves a lot of time. No more copying of container ids,
                    just select the container via arrows/text, enter, go.
                </p>
                <hr/>
                <p>I hoped you liked this script, there will be more in the future.</p>
            </div>
        </div>
    );
}