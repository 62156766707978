import React from "react";
import "../blog-entry-preview.scss"
import "./../blog-entry-style.scss"
import ImageContainer from "../../image-container/image-container";
import CodeContainer from "../../code-container/code-container";

export default function PostmanFeatureFlags() {

    return (
        <div className="blog-entry">
            <div className="content">
                <h1>Feature Flags & Iteration Data Fixes</h1>
                <p>Ahoy.</p>
                <p>
                    This Log entry is about feature flags in <a target="_blank" rel="noreferrer"
                                                                href={"https://www.postman.com/"}>Postman</a> and
                    an iteration data fix, which in combination allow you to test your
                    application completely with different scenarios. An example collection is attached at the bottom.
                </p>
                <ul>
                    <li><a className={"jump-link"} href="#feature-flags">Feature flags in Postman</a>, the main reason
                        for this log entry.
                    </li>
                    <li><a className={"jump-link"} href="#iteration-fix">A correct (and intuitive) behaviour for
                        IterationData</a>, for a better usage of iterations.
                    </li>
                    <li><a className={"jump-link"} href="#flags-x-iteration">The Combination of both tricks in
                        practice</a>, gives you an example how it could look.
                    </li>
                </ul>
                <hr/>
                <h3>
                    <section id="feature-flags">Feature flags in CollectionRunner</section>
                </h3>
                <p>
                    {"Imagine you have 4 rest calls: A, Red, Blue & D and sometimes you need to run A -> Blue -> D and sometimes A -> Red -> D. "}
                    Most of the Postman users already know that you can chain requests together via:
                </p>
                <CodeContainer text={"set next request for Postman Runner"}>
                    {"postman.setNextRequest(\"name of the next request\");"}
                </CodeContainer>
                <p>
                    But how would you do this branching logic?</p>
                <p>
                    You could only add the needed rest calls into your runner, which is a lot of manual work each time
                    and its harder to test both scenarios.</p>
                <p>
                    You could also add feature flags in your code and enable/disable them via comments, but this is
                    also
                    a lot of manual work, but easier to test, since it could work via environment/global variables.
                </p>
                <p>
                    Letting PMs or other devs (who are maybe not so familiar with the collection) fiddle with code
                    is not a nice experience. Sometimes you only want the user to be able to choose between 2-4 premade
                    paths and nothing else.
                </p>
                <p>
                    <b>Here comes the trick:</b> You can use QueryParameters of requests as feature flags. They dont
                    disappear when disabled. They are stored in the collection and you can even add a description to
                    each parameter, to explain what is turned on/off.
                </p>
                <ImageContainer path={require("./query-parameter-trick.png")}
                                text={"Postman query parameter trick with echo api"}></ImageContainer>
                <h3>
                    Explanation
                </h3>
                <p>
                    To have a good reuseable implementation, you can use the Postman echo api to echo back all your
                    parameters, iterate over them via Lodash and store them as variables with the following code:</p>
                <CodeContainer text={"iterate over echo response and set as variables"}>
                    {"_.forEach(JSON.parse(responseBody).args, (k,v) => {\n\tpm.variables.set(v, k);\n});"}
                </CodeContainer>
                <p>
                    From now on it works completely automatic as long as you make sure that this request
                    runs first in your Postman runner. You can copy and paste the feature flag request into
                    different parts of your collection without changing the request. Its truly plug and play.
                </p>
                <p>
                    You can now override ANY variable in every subsequent postman call without writing any code,
                    changing code and only by adding a new QueryParameter with the same name as the variable used in
                    the
                    requests.
                </p>
                <h3>Branching requests</h3>
                <p>A concrete implementation is a branching logic within your runner. You can make Requests
                    optional/allow branching with a feature flag and the following code in a Test section of a
                    request:</p>
                <CodeContainer text={"Branching for Postman runner"}>
                    {"if(pm.variables.get(\"color\") === \"blue\"){\n" +
                        "    postman.setNextRequest(\"blue\");\n" +
                        "} else if(pm.variables.get(\"color\") === \"red\"){\n" +
                        "    postman.setNextRequest(\"red\");\n" +
                        "}"}
                </CodeContainer>

                <p>This is how it looks:</p>

                <ImageContainer path={require("./postman-branching.gif")}
                                text={"Branching in Postman via Feature Flags"}></ImageContainer>
                <p>
                    PS: if you somehow create some other variables in later requests, make sure to store them via
                    <b> pm.globals.setVariable()</b>, to make sure that the QueryParameters have a higher precedence
                    over the later defined variables and you can override everything with the QueryParameter trick.
                </p>
                <hr/>
                <h3>
                    <section id="iteration-fix">A correct (and intuitive) behaviour for IterationData</section>
                </h3>
                <p>
                    Iterations in Postman are rarely used and i think its because of this weird behaviour:
                    The precedence of "iteration" variables is LOWER then variables and environment variables,
                    so its pretty hard to use the iteration data in any Postman runner when the requests were not
                    directly designed for it.
                </p>
                <ImageContainer path={require("./iterationInput.png")}
                                text={"Iteration in Postman"}></ImageContainer>
                <p>
                    But you can attach the following code to the first api call in your postman runner (hint, this
                    is perfect in combination with the FeatureFlag trick from above), to give each
                    iteration variable the highest precedence possible.
                </p>
                <CodeContainer text={"Iterate over iteration data and set variable"}>
                    {"_.forEach(pm.iterationData.toJSON().values, (k,_) => {\n\tpm.variables.set(k.key, k.value);\n});"}
                </CodeContainer>
                <p>
                    What can you do with it? Now you can pass in the following json file to run the 2
                    scenarios described above and it will just work as expected: One iteration is executing the
                    "red" request the other iteration is executing the "blue" request instead.
                </p>
                <CodeContainer text={"scenarios.json"}>
                    {"[\n" +
                        "\t{\n" +
                        "\t\t\"color\": \"red\"\n" +
                        "\t},\n" +
                        "\t{\n" +
                        "\t\t\"color\": \"blue\"\n" +
                        "\t}\n" +
                        "]"}
                </CodeContainer>
                <p>And if you use the scenario.json from above it could look like this:</p>
                <ImageContainer path={require("./iteration-example.png")}
                                text={"2 Iterations with different executions"}></ImageContainer>
                <p> PS: make sure that this code runs AFTER the FeatureFlag method if you decide to use both. </p>
                <hr/>
                <h3>
                    <section id="flags-x-iteration">Summary</section>
                </h3>
                <p>
                    With a small amount of time investment, you can provide the user a lot of QOL improvements via feature flags.
                    You now have an configurable, reuseable, self documenting input layer into any postman runner.
                </p>
                <p>You can checkout the example by importing the following url into Postman:</p>
                <CodeContainer text={"download link"}>
                    {"https://code-captain.blog/files/FeatureFlags.postman_collection.json"}
                </CodeContainer>

                <p>I hope you liked this trick, more will come.</p>
            </div>
        </div>
    )
}