import React from "react";
import "../blog-entry-preview.scss"
import "./../blog-entry-style.scss"
import {Light as SyntaxHighlighter} from 'react-syntax-highlighter';
import ImageContainer from "../../image-container/image-container";
import CodeContainer from "../../code-container/code-container";
import jsonData from './file1.json';

export default function ProvisioningOfADevEnvi() {
    return (
        <div className="blog-entry">
            <div className="content">
                <h1>Automatic provision a dev environment</h1>
                <p>A description of an automatic develop environment provisioning
                </p>
                <ul>
                    <li>
                        <a className={"jump-link"} href="#task">The task</a>
                    </li>
                    <li>
                        <a className={"jump-link"} href="#section-two">The tools</a>
                    </li>
                    <li>
                        <a className={"jump-link"} href="#section-three">The Idea</a>
                    </li>
                </ul>
                <hr/>
                <h3>
                    <section id="task">The task</section>
                </h3>
                <p>
                    At work i use VirtualBox on a Windows host machine to run a linux distro as guest for development.
                    Every new hire needs to do the same steps of setting up VirtualBox and installing Manjaro/PopOs.
                    I started with the idea of automating this, since the steps are always the same and pretty boring.
                </p>
                <p>
                    First i thought about writing a simple shell script to install everything, but why stop here? We can
                    automate the complete installation of the operating system too. And even automate the installation
                    of personal scripts, programs and shortcuts.
                </p>
                <p>
                    The benefits are pretty obvious: 1 script to install a
                    complete dev environment done without any manual work in ~ 20 minutes of running some scripts.
                </p>
                <h3>
                    <section id="section-two">The tools</section>
                </h3>
                <p>
                    I looked into two different ways to create a virtual machine automatically:
                    <a href={"https://www.vagrantup.com/"}>Vagrant</a> and <a href={"https://www.packer.io/"}>Packer</a>.
                    Both are by Hashicorp and solve this problem, but in different ways:
                </p>
                <h5>Vagrant vs Packer</h5>
                <p>
                    Packer enters some commands on the VM via keypresses, enters via SSH to install some
                    scripts and in the end i can remove packer and use the result VM without any problems. If i use
                    vagrant, i need to have vagrant installed for the whole time to start up the vm.
                    This is rather clunky in my opinion. I prefer to have no dependencies in the end.
                    Also the packer.exe doesnt need to be installed and can be just downloaded on the fly, which
                    makes this even more automated. The installation script just needs to also download packer.
                </p>
                <p>
                    Packer is a declarativ priovisioning tool, so overall we just have to find the correct configuration
                    for our packer config file and we are ready to go.
                </p>
                <p>
                    It can provision the VM via key presses and via ssh connection. The problem is that in the beginning
                    we dont have an ssh ready vm, so we first need to install everything via key presses and then
                    activate
                    ssh to do the rest via scripts.
                </p>
                <h5>VirtualBox vs ?</h5>
                <p>
                    To make this short: I use VirtualBox, because it works on Windows and i personally dont encounter
                    that many issues with it. And its compatible with Packer, but Packer is compatible with other Vms
                    too, feel free to check other solutions.
                </p>
                <h3>
                    <section id="section-three">The Plan:</section>
                </h3>
                <p>
                    While trying out packer, i ran into 1 big problem: time. Trying some settings, then
                    waiting 15 minutes installing, then seeing it not working is a pretty time consuming workflow.
                    So i started to split the complete setup into 3 parts to not run completely insane:

                    <ul>
                        <li>The basic installation of the OS (here is packer pressing "real" key strokes)</li>
                        <li>The system update (here we are just updating the base dependencies by entering via ssh.)
                        </li>
                        <li>Installation of my scripts, tools & programs (this step takes the most time)</li>
                    </ul>
                </p>
                <p>
                    Packer allows us to import existing VirtualBox images as a base, so first we create a complete
                    new image, and then reuse the image from the step before and create a new one. This way we can
                    restart the script and dont have to run all the working existing steps.
                </p>
                <h3>
                    <section id="section-four">Step 1: OS installation</section>
                </h3>
                <p>
                    I chose Manjaro i3 edition, because its Arch and now i can say that i use Arch btw.
                </p>
                <p>
                    Below is the explanation for the important configs
                    <ol type={"1"}>
                        <li>iso_url: url to the iso</li>
                        <li>boot_command: the command which is getting typed via packer</li>
                        <li>communicator: none, since we are doing everything via keyboard</li>
                        <li>vboxmanage: some VirtualBox settings, which are always set. Like Shared folder and cpu
                            count (we will come to this later).
                        </li>
                    </ol>
                </p>
                <CodeContainer text={"packer.json"}>
                    {"{\n" +
                        "    \"builders\": [\n" +
                        "        {\n" +
                        "            \"vm_name\": \"{{user `vmname`}}-temp1\",\n" +
                        "            \"type\": \"virtualbox-iso\",\n" +
                        "            \"output_directory\": \"./i3/output-i3/\",\n" +
                        "            \"iso_checksum\": \"sha1:{{user `i3-image-hash`}}\",\n" +
                        "            \"iso_url\": \"{{user `i3-image`}}\",\n" +
                        "            \"guest_os_type\": \"ArchLinux_64\",\n" +
                        "            \"boot_wait\": \"5s\",\n" +
                        "            \"boot_command\": [\n" +
                        "                \"<enter><wait50><wait4><leftSuperOn>i<leftSuperOff><wait20>\",\n" +
                        "                \"<leftAltOn><wait10>e<up><up>n<wait10>n<wait10>n<wait10>n<wait10><leftAltOff>\",\n" +
                        "                \"dev<tab><tab>devbox<tab>1234<tab>1234<tab><spacebar><tab><spacebar><leftAltOn>n<wait10>i<wait10>i\",\n" +
                        "                \"<wait220>d<leftAltOff>\",\n" +
                        "                \"<leftSuperOn><enter><leftSuperOff><wait3>shutdown now<enter>\"\n" +
                        "            ],\n" +
                        "            \"disk_size\": \"{{user `disksize`}}\",\n" +
                        "            \"hard_drive_interface\": \"sata\",\n" +
                        "            \"communicator\": \"none\",\n" +
                        "            \"guest_additions_mode\": \"disable\",\n" +
                        "            \"virtualbox_version_file\": \"\",\n" +
                        "            \"shutdown_command\": \"sudo shutdown now\",\n" +
                        "            \"headless\": \"false\",\n" +
                        "            \"bundle_iso\": true,\n" +
                        "            \"cpus\": \"{{user `cpu-count`}}\",\n" +
                        "            \"memory\": \"{{user `memory`}}\",\n" +
                        "            \"vboxmanage\": [\n" +
                        "                [\n" +
                        "                    \"modifyvm\",\n" +
                        "                    \"{{.Name}}\",\n" +
                        "                    \"--vram\",\n" +
                        "                    \"128\"\n" +
                        "                ],\n" +
                        "                [\n" +
                        "                    \"sharedfolder\",\n" +
                        "                    \"add\",\n" +
                        "                    \"{{.Name}}\",\n" +
                        "                    \"--name=shared\",\n" +
                        "                    \"--hostpath={{user `shared-folder-name`}}\"\n" +
                        "                ],\n" +
                        "                [\n" +
                        "                    \"modifyvm\",\n" +
                        "                    \"{{.Name}}\",\n" +
                        "                    \"--graphicscontroller\",\n" +
                        "                    \"{{user `graphics-controller`}}\"\n" +
                        "                ],\n" +
                        "                [\n" +
                        "                    \"modifyvm\",\n" +
                        "                    \"{{.Name}}\",\n" +
                        "                    \"--clipboard-mode\",\n" +
                        "                    \"bidirectional\"\n" +
                        "                ],\n" +
                        "                [\n" +
                        "                    \"modifyvm\",\n" +
                        "                    \"{{.Name}}\",\n" +
                        "                    \"--draganddrop\",\n" +
                        "                    \"disabled\"\n" +
                        "                ]\n" +
                        "            ]\n" +
                        "        }\n" +
                        "    ]\n" +
                        "}"}
                </CodeContainer>
                <p>
                    I use the following key presses to install Manjaro i3 and to shut it down.
                    The name of the user is "dev" and the password will be "1234". Change it afterwards to something
                    else.
                </p>
                <CodeContainer text={"Key presses to install Manjaro I3 edition"}>
                    {"<enter><wait50><wait4><leftSuperOn>i<leftSuperOff><wait20>\n" +
                        "<leftAltOn><wait10>e<up><up>n<wait10>n<wait10>n<wait10>n<wait10>\n<leftAltOff>" +
                        "dev<tab><tab>devbox<tab>1234<tab>1234<tab><spacebar><tab><spacebar>\n<leftAltOn>n<wait10>i<wait10>i" +
                        "<wait220>d<leftAltOff>" +
                        "<leftSuperOn>\n<enter><leftSuperOff><wait3>shutdown now<enter>"}
                </CodeContainer>
                <p>
                    One problem with this script is that it is time based and if your PC is slow, then this will fail.
                    I went with super long wait times in between, to give each PC enough to time to catch up. So overall
                    it can be much faster, but this is just playing it safe.
                </p>
                <h3>
                    <section id="step-2">Step 2: Updating all dependencies</section>
                </h3>
                <p>
                    Step 2 will consume the image from step 1 and create a completely new image. This means that we
                    will need to provide a completely new json file:
                </p>
                <CodeContainer text={"abc"}>
                    {JSON.stringify(jsonData, null, 2)}
                </CodeContainer>
                <p>
                    The next part is updating the dependencies. I do this via packer ssh connection.
                    First we type in the code to start up an ssh server:
                </p>
                <CodeContainer text={"abc"}>
                    {"\"boot_command\": [\n" +
                        "                \"<wait60><leftSuperOn><enter><leftSuperOff><wait5>\",\n" +
                        "                \"systemctl start sshd.service<enter><wait5>{{user `password`}}<enter>\"\n" +
                        "            ],"}
                </CodeContainer>
                <p>Packer waits for the open connection and uploads the files, then executes the script. </p>
                <CodeContainer text={"manjaro-update.sh"}>
                    {"#!/bin/bash\n" +
                        "\n" +
                        "echo $1 | sudo -S pacman-mirrors --fasttrack 5 \n" +
                        "sleep 5\n" +
                        "echo $1 | sudo -S rm /var/lib/pacman/db.lck\n" +
                        "echo $1 | sudo -S pacman -Syyu --noconfirm --overwrite=/usr/bin/dunstify"}
                </CodeContainer>
                <h3>
                    <section id="step-3">Step 3: Installing the dotfiles</section>
                </h3>
                <p>
                    The next step is to install all the dotfiles.
                </p>
                <p>
                    There are multiple dotfiles manager, i went with
                    YADM, yet another dotfile manager. It works exactly like git, all the commands are compatible, but
                    instead of invoking git commit, you invoke yadm commit.
                </p>
                <p>
                    A big problem i ran into is the installation of everything, without prompting the user for a password.
                    The trick here is that you "turn off" the sudo authentication for a short amount of time. We can
                    do this via a new sudoers file:
                </p>
                <CodeContainer text={"Turning off sudo via sudoers file"}>
                    {"echo \"1234\" | sudo -S sh -c 'echo \"dev ALL=(ALL:ALL) NOPASSWD: ALL\" | tee /etc/sudoers.d/dont-prompt-for-sudo-password'\n"}
                </CodeContainer>
                <p>We turn this off (and sudo authentication on again) by just deleting this file via</p>
                <CodeContainer text={"Turning on sudo by deleting file"}>
                    {"echo \"1234\" | sudo -S rm -f /etc/sudoers.d/dont-prompt-for-sudo-password\n"}
                </CodeContainer>
                <p>
                    The nice thing with YADM is that you can invoke a bootstrap script via yadm bootstrap,
                    when you downloaded all files, so this is perfect for a user specific setup.
                </p>
                <p>The full installation file for YADM looks like below. The git url is passed via parameters and can be
                    freely choosen. Also the creation of a shared folder is automatically done.
                </p>
                <CodeContainer text={"Installation of dotfiles"}>
                    {"#!/bin/sh -e\n" +
                        "\n" +
                        "echo \"--- turning sudo off ---\"\n" +
                        "echo \"1234\" | sudo -S sh -c 'echo \"dev ALL=(ALL:ALL) NOPASSWD: ALL\" | tee /etc/sudoers.d/dont-prompt-for-sudo-password'\n" +
                        "\n" +
                        "echo \"--- local to DE ---\"\n" +
                        "sudo localectl set-keymap de\n" +
                        "\n" +
                        "echo \"--- installing yay ---\"\n" +
                        "sudo pacman -S yay --noconfirm\n" +
                        "\n" +
                        "echo \"--- yay setups ---\"\n" +
                        "yay --save --answerclean All\n" +
                        "yay --save --answerdiff None\n" +
                        "\n" +
                        "echo \"--- installing yadm ---\"\n" +
                        "yay -S yadm-git --noprovides\n" +
                        "\n" +
                        "echo \"--- cloning provided repo ---\"\n" +
                        "yadm clone $2 -f --no-bootstrap\n" +
                        "yadm rm --cached -r .\n" +
                        "yadm reset --hard\n" +
                        "\n" +
                        "echo \"--- executing bootstrap ---\"\n" +
                        "chmod +x ~/.config/yadm/bootstrap\n" +
                        "yadm bootstrap\n" +
                        "\n" +
                        "echo \"--- mounting shared folder ---\"\n" +
                        "mkdir ~/shared\n" +
                        "sudo mount -t vboxsf shared ~/shared\n" +
                        "echo \"shared\t/home/$3/shared\tvboxsf\tdefaults\t0\t0\" | sudo tee /etc/fstab\n" +
                        "echo \"vboxsf\" | sudo tee /etc/modules\n" +
                        "\n" +
                        "echo \"--- turning sudo on ---\"\n" +
                        "echo \"1234\" | sudo -S rm -f /etc/sudoers.d/dont-prompt-for-sudo-password"}
                </CodeContainer>

                <hr/>
                <p>
                    You can find the full repo <a href={"https://github.com/AskMeAgain/Devbox"}>here</a>. The readme
                    should be enough to run everything.
                </p>
            </div>
        </div>
    )
}