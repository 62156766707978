import React from "react";
import "./sticky-subscribe.scss";

export default function StickySubscribe() {
    return (
        <div className={"rss-feed-container"}>
            <a href={"https://www.code-captain.blog/rss-feed.xml"}
               target={"_blank"}>Subscribe via RSS</a>
        </div>
    );
}