import React from 'react';
import ReactDOM from 'react-dom/client';
import Header from "./components/header/header";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Waves from "./components/waves/waves";
import './main.scss';
import './mui-accordion-style.scss';
import Preview from "./components/preview/preview";
import ExpandableContent from "./components/expandable-content/ExpandableContent";
import Footer from "./components/footer/Footer";
import "./fonts/source-code-pro/SourceCodePro-Medium.ttf"
import StickySubscribe from "./components/sticky-subscribe/sticky-subscribe";

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <BrowserRouter>
            <Header/>
            <Routes>
                <Route path="/*" element={<ExpandableContent/>}/>
            </Routes>
            <Waves/>
            <Preview/>
            <Footer/>
            <StickySubscribe/>
        </BrowserRouter>
    </React.StrictMode>
);


