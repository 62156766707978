import React from "react";
import "./code-container.scss";
import {Light as SyntaxHighlighter} from "react-syntax-highlighter";

export default function CodeContainer({text, children}: { text: string, children: string }) {
    return (
        <div className={"code-container"}>
            <SyntaxHighlighter language="javascript" wrapLines={true}>
                {children}
            </SyntaxHighlighter>
            <label>{text}</label>
        </div>
    );
}