import React from "react";
import {Link} from "react-router-dom";
import "./footer.scss";

export default function Footer() {
    return (
        <div className={"footer"}>
            <Link to={"/contact"} onClick={() => {
                window.scrollTo({top: 0, behavior: 'smooth'});
            }}>Contact</Link>
            <Link to={"/privacy"} onClick={() => {
                window.scrollTo({top: 0, behavior: 'smooth'});
            }}>Privacy Policy</Link>
        </div>);
}