import CodeContainer from "../../code-container/code-container";
import ImageContainer from "../../image-container/image-container";
import React from "react";

export function PostfixInIntellij() {
    return (
        <div className="blog-entry">
            <div className="content">
                <h1>Postfixes in Intellij Idea</h1>
                <ul>
                    <li>
                        <a className={"jump-link"} href="#introduction">What is a postfix?</a>
                    </li>
                    <li>
                        <a className={"jump-link"} href="#list">Useful Postfixes</a>
                    </li>
                </ul>
                <hr/>
                <h3>
                    <section id="introduction">What is a postfix?</section>
                </h3>
                <p>
                    A Postfix in Intellij Idea is an entry in the code completion list which triggers a transformation
                    of your current line. For example when typing <span>.var</span> intellij will introduce a variable
                    for this
                    expression.
                </p>
                <ImageContainer text={".var postfix"} path={require("./postfix.gif")}></ImageContainer>
                <p>
                    All of the Postfixes are only realized via text replacements of a template:
                </p>
                <CodeContainer text={"Example Postfix template"}>
                    {"org.assertj.core.api.Assertions.assertThat($EXPR$).$END$"}
                </CodeContainer>
                <p>
                    The guys at JetBrains made sure that a lot of different
                    Postfix implementations are already included in IntelliJ by default:
                </p>
                <ImageContainer path={require("./postfix-settings.png")} text={"Postfix menu"}/>
                <p>
                    But more powerful is the ability to set your own Postfixes via dialog:
                </p>
                <ImageContainer path={require("./create-postfix.png")} text={"Create a postfix"}/>
                <ul>
                    <li>Key specifies what you need to type to trigger the Postfix</li>
                    <li>Applicable Expression types specifies when the Postfix will be shown (Non Void is what you want
                        to do most of the time)
                    </li>
                    <li>Write the expected code in the inputfield below.</li>
                    <li>$EXPR$ will be the expressing.</li>
                    <li>$END$ specifies the location of the cursor afterwards</li>
                    <li>Use static import is useful if you want to have everything imported as static.</li>
                </ul>
                <h3>
                    <section id="list">Useful Postfixes</section>
                </h3>
                <p>
                    Below is a table of useful Postfixes. Not all of them are by default integrated, but they should be
                    fairly simple to include. Just insert "$EXPR$" somewhere between.
                </p>
                <table>
                    <tr>
                        <th style={{width: "10px"}}>Postfix</th>
                        <th>Before</th>
                        <th>After</th>
                        <th style={{width: "10px"}}>Default</th>
                    </tr>
                    <tr>
                        <td>.var</td>
                        <td>"abc".var</td>
                        <td>var abc = "abc";</td>
                        <td>yes</td>
                    </tr>
                    <tr>
                        <td>.assertThat</td>
                        <td>testResultVariable.assertThat</td>
                        <td>assertThat(testResultVariable).</td>
                        <td>no</td>
                    </tr>
                    <tr>
                        <td>.not</td>
                        <td>if(aBooleanVariable.not)</td>
                        <td>if(!aBooleanVariable)</td>
                        <td>yes</td>
                    </tr>
                    <tr>
                        <td>.tojson</td>
                        <td>variable.tojson</td>
                        <td>JSON.stringify(variable)</td>
                        <td>no</td>
                    </tr>
                    <tr>
                        <td>.log</td>
                        <td>variable.log</td>
                        <td>log.info(variable);</td>
                        <td>yes</td>
                    </tr>
                    <tr>
                        <td>.if</td>
                        <td>variable.if</td>
                        <td>if(variable)</td>
                        <td>yes</td>
                    </tr>
                </table>
                <p>
                    Here are the definitions in case you want to try them out:
                </p>
                <CodeContainer text={"Custom postfixes"}>
                    {"org.assertj.core.api.Assertions.assertThat($EXPR$).\n\n" +
                        "JSON.stringify($EXPR$)"}
                </CodeContainer>
                <p>
                    PS: One thing to note is that you can also register a Live Template with basically the same content
                    (minus the $EXPR$ part) to have the postfix available without having to type anything before.
                </p>
                <hr/>
                <p>That was it for now with Postfixes in Intellij. More tricks will come!</p>
            </div>
        </div>
    );
}