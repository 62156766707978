import React from "react";
import {Link} from "react-router-dom";
import './header.scss'

export default function Header() {
    return (
        <div className="navbar">
            <div>
                <Link to={"/"}>Home</Link>
                <Link to={"/postfixes-in-intellij"}>Postfixes in Intellij</Link>
                <label>Featured</label>
            </div>
            <h1 className="title">(B)logbook of the Code Captain</h1>
        </div>
    );
}