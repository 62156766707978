import * as React from 'react';
import {useEffect, useState} from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import PostmanFeatureFlags from "../blog-entries/postman-feature-flags/PostmanFeatureFlags";
import {useLocation} from "react-router-dom";
import Contact from "../blog-entries/contact/contact";
import ProvisioningOfADevEnvi from "../blog-entries/provisioning-of-a-dev-envi/ProvisioningOfADevEnvi";
import Privacy from "../blog-entries/privacy/privacy";
import CollectionDownloadUrl from "../blog-entries/collection-download-url/CollectionDownloadUrl";
import PostmanApplications from "../blog-entries/postman-applications/postman-applications";
import FzfWithDocker from "../blog-entries/fzf-with-docker/fzf-with-docker";
import VirtualBoxLink from "../blog-entries/virtual-box-link/virtual-box-link";
import {PostfixInIntellij} from "../blog-entries/postfix-in-intellij/postfix-in-intellij";

export default function ExpandableContent() {
    const location = useLocation();

    let [expanded, setExpanded] = useState(true);
    let [openPage, setOpenPage] = useState("/");

    useEffect(() => {
        if (location.pathname === "/") {
            setExpanded(false);
        } else {
            setOpenPage(location.pathname);
            setExpanded(true);
        }
    }, [location]);

    return (
        <div className="accordion-content">
            <Accordion expanded={expanded}>
                <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                </AccordionSummary>
                <AccordionDetails>
                    {openPage === "/feature-flags-in-postman" && (<PostmanFeatureFlags/>)}
                    {openPage === "/collection-download-url" && (<CollectionDownloadUrl/>)}
                    {openPage === "/provisioning-of-a-dev-envi" && (<ProvisioningOfADevEnvi/>)}
                    {/*{openPage === "/postman-applications" && (<PostmanApplications/>)}*/}
                    {openPage === "/fzf-with-docker" && (<FzfWithDocker/>)}
                    {openPage === "/postfixes-in-intellij" && (<PostfixInIntellij/>)}
                    {/*{openPage === "/virtual-box-link" && (<VirtualBoxLink/>)}*/}
                    {openPage === "/contact" && (<Contact/>)}
                    {openPage === "/privacy" && (<Privacy/>)}
                </AccordionDetails>
            </Accordion>
        </div>
    );
}