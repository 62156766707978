import React from "react";
import "./image-container.scss";

export default function ImageContainer({path, text}: { path: string; text: string }) {
    return (
        <div className={"image-container"}>
            <div className={"test"}>
                <div className={"inner-text"}>
                    <img alt={text} src={path}></img>
                    <label>{text}</label>
                </div>
            </div>
        </div>
    );

}