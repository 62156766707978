import React from "react";

export default function Privacy() {
    return (
        <div className="blog-entry">
            <div className="content">
                <div>
                    <h1>Privacy</h1>
                    <p>No ads.</p>
                    <p>No tracking.</p>
                    <p>No cookies.</p>
                    <p>No data is collected.</p>
                    <p>This blog exists only to share interesting things.</p>
                </div>
            </div>
        </div>
    );
}